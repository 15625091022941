import styled from 'styled-components';
import { Button } from '../RegisterForm/register-styling';


export const Header = styled.div`
    width: 100vw;
`;

export const Container = styled.div`
    max-width: 90%;
    margin: 0 auto;
    margin-bottom: 90px;
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 100px;

    & p {
        font-size: 1.8rem;
        text-align: center;
        margin-bottom: 40px;
    }
`;

export const ButtonInscribete = styled(Button)`
    padding: 10px 50px;
`;

export const LogoRecovery = styled.img`
    width: 70%;
`;

export const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;

    & .form-hbspt {
        width: 60%;
        margin: 0 auto;
    }
`;

export const FormWrapper = styled.div`
    max-width: 800px;
    margin: 0 auto;

    @media(max-width: 800px) {
        & {
            max-width: 350px;
        }
    }
`;