import styled from 'styled-components';

export const Modal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display:block;
    opacity: ${props => props.show ? "1" : "0"};
    transition: all 0.2s ease-in;
    visibility: ${props => props.show ? "visible" : "hidden"};
    
    `;

export const ModalMain = styled.div`
    position:fixed;
    background: white;
    width: 50%;
    height: auto;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    border-radius: 0.3rem;
    @media only screen and (max-width: 768px) {
        width: 90%;
    }
`;
export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 3rem;
    border-bottom: 1px solid #e9ecef;
    & svg {
        cursor: pointer;
        margin-bottom: 0px;
    }
`;
export const Title = styled.h3`
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 1.5;
    margin-bottom: 0px
`;