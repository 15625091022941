import styled from 'styled-components';
import { ButtonInscribete } from '../Header/header-styling';


export const Step = styled.div`
    text-align: center;
    padding: ${props => props.number === "1" ? "0px 0px;" : "0px 15px;"}

    & h3 {
        font-size: 2rem;
    }

    & p {
        font-size: 1.8rem;
        font-style: italic;
    }
`;

export const ButtonInscribeteStep = styled(ButtonInscribete)`
    display: block;
`;