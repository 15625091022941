import { Footer as FooterWrapper } from './footer-styling';

const Footer = () => {
    return(
        <FooterWrapper>
            <ul>
                <li><a href="https://info.lafabricaplay.com/">Terminos y Condiciones</a></li>
                <li><a href="https://info.lafabricaplay.com/">Aviso de Privacidad</a></li>
            </ul>
            <a href="https://www.lafabricaplay.com/">La Fábrica Play®</a>
        </FooterWrapper>
    );
}

export default Footer;