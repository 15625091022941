import styled from 'styled-components';

export const Footer = styled.footer`
    display: flex;
    justify-content: space-between;
    align-center: center;
    padding: 3rem 1rem;
    border-top: 1px solid #e9ecef;

    a {
        font-size: 1.5rem;
        color: black;
    }
    a:hover {
        color: rgba(0, 0, 0, 0.7);
    }

    & ul {
        display: flex; 
        li {
            margin-right: 15px;
            a{
                color: black;
                font-size: 1.5rem;
            }
            a:hover {
                color: rgba(0, 0, 0, 0.7);
            }
        }
    }
`;