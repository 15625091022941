import { Quantity, Description } from './feature-styling';

const Feature = ({ quantity, description }) => {
    return(
        <div>
            <Quantity>{ quantity }<span>+</span></Quantity>
            <Description>{ description }</Description>
        </div>
    );
}

export default Feature;