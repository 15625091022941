import styled from 'styled-components';

export const GroupInfo = styled.div`
    display: flex;
    margin-bottom: 50px;

    @media(max-width: 768px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;

        & div {
            margin-bottom: 25px;
        }
    }
`;
export const Info = styled.div`
    width: 50%;
    @media(max-width: 768px) {
        width: 100%;
    }
    & h3 {
        font-size: 4rem;
        font-weight: bold;
        text-align: center;
    }

    & p {
        font-size: 2rem;
        font-weight: 400;
        text-align:  ${props => props.doctors ? "left" : "center"};
        padding: 0px 25px;
        line-height: 1.5em;
        margin-top: ${props => props.doctors ? "0px" : "20px"};
        margin-bottom: ${props => props.doctors && "5px" };

    }
    & p.doctor--first {
        margin-top: 20px;
    }


`;