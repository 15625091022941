import styled from 'styled-components';
import { Container } from '../Header/header-styling';


export const Title = styled.h2`
    font-size: 4rem;
    text-align: center;
    font-weight: 600;
    margin-bottom: 60px;
`;

export const Steps = styled.div`
    display: flex;
    justify-content: space-between;
    
    @media only screen and (max-width: 500px) {
        flex-direction: column;
        & div {
            margin-bottom: 25px;
        }
      }
`;

export const ContainerProcess = styled(Container)`
    margin-bottom: 100px;
`;