import styled from 'styled-components';

export const Form = styled.form`
    padding: 1rem 3rem;
`;

export const Label = styled.label`
    display: block;
    font-size: 1.5rem;
    font-weight: 600;
`;

export const Input = styled.input`
    width: 100%;
    padding: 10px 5px;
    border-radius: 0.25rem;
    font-size: 1.5rem;
    border: 1px solid rgba(0,0,0,.1);

    &:focus {
        outline:none !important;
        outline-width: 0 !important;
        box-shadow: none;
    }
`;

export const GroupForm = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;

    & div {
        width: 100%;
        margin-right: 10px;
    }

    & div:last-child {
        margin-right: 0px;
    }
    @media only screen and (max-width: 768px) {
        flex-direction: column;

      }
`;

export const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    widht: 100%;
`;

export const Button = styled.a`
    padding: 5px 40px;
    border-radius: 6px;
    background-color: #309605;
    border: none;
    font-size: 1.8rem;
    font-weight: 600;
    color: white;
    margin-top:15px;
    &:hover {
        color: white;
        cursor: pointer;
    }
`;

export const ButtonSubmit = styled.button`
    padding: 5px 30px;
    border-radius: 6px;
    background-color: #309605;
    border: none;
    font-size: 1.8rem;
    font-weight: 600;
    color: white;
    margin-top:15px;
    &:hover {
        color: white;
        cursor: pointer;
    }
`;

export const ErrorInput = styled.p`
    color: red;
    font-size: 1.2rem;
`;