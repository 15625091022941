import { Loading as LoadingWrapper } from './loading-styling';

const Loading = () => {
    return(
        <LoadingWrapper>
            <div></div><div></div><div></div><div></div>
        </LoadingWrapper>
    );
}

export default Loading;