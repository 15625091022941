import { Header as HeaderWrapper, Container, Wrapper, ButtonInscribete, LogoRecovery } from './header-styling';

const Header = ({ showModal }) => {
    return(
        <HeaderWrapper>
            <Container>
                <Wrapper>
                    <LogoRecovery src="/assets/img/logo_recovery_01.png"/>
                    {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec enim tellus, consequat semper congue vel, ullamcorper at orci. Proin commodo, tortor a blandit vulputate, leo lacus laoreet nisi, vitae porta tortor velit eget mi. Nulla facilisi. Mauris feugiat, felis ut pellentesque ultrices, lacus mi convallis felis, in facilisis dolor sapien a ex.</p>
                    <ButtonInscribete onClick={showModal}>¡Inscríbete Ahora!</ButtonInscribete> */}
                </Wrapper>
            </Container>
        </HeaderWrapper>
    );
}

export default Header;