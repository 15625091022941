import { Container } from '../Header/header-styling';
import { Info, GroupInfo } from './program-styling';

const Program = () => {
    return(
        <Container>
            <GroupInfo>
                <Info>
                    <h3>¿Qué es?</h3>
                    <p>Programa diseñado para el síndrome post-covid, enfocado para fortalecer y recuperar la condición física, mental y emocional.</p>
                </Info>
                <Info>
                    <h3>¿En qué consiste?</h3>
                    <p>Ayudar a las personas a recuperar su estilo de vida a través de ejercicios de respiración, clases de yoga, Stretching, fuerza y cardio durante 6 semanas, acompañado con conferencias de orientación alimenticia, inteligencia emocional y asesoría médica.</p>
                </Info>
            </GroupInfo> 
            <GroupInfo>
                <Info>
                    <h3>¿A quién va dirigido?</h3>
                    <p>Dirigido a las personas que estuvieron en confinamiento presentando síntomas leves.</p>
                </Info>
                <Info doctors={true}>
                    <h3>¿Avalado por?</h3>
                    <p className="doctor--first">Dr. Francisco Moreno (Especialista en Medicina interna e infectología)</p>
                    <p>Marcos Jassan (Master enYoga)</p>
                    <p>Coral Mujaes (Conferencista y Coach de Alto Rendimiento)</p>
                    <p>Victoria Davila (Nutrióloga Clínica).</p>
                </Info>
            </GroupInfo> 
        </Container>
    );
}

export default Program;