import { useState } from 'react';
import { ToggleNavbar as ToggleNavbarWrapper, ToggleButton, NavbarResponsive } from './toggleNavbar-styling';

const ToggleNavbar = () => {
    const [ open, setOpen ] = useState(false);

    const showMenu = () => {
        setOpen(!open);
    }
    return(
        <ToggleNavbarWrapper >
            <ToggleButton onClick={showMenu} open={open}>
                <div></div>
            </ToggleButton>
            <NavbarResponsive open={open}>
                <li>
                    <a href="https://www.lafabricaplay.com/browse">All Clases</a>
                </li>
                <li>
                    <a href="https://www.lafabricaplay.com/live">Calendario Live</a>
                </li>
                <li>
                    <a href="https://www.lafabricaplay.com/disciplinas">Disciplinas</a>
                </li>
                <li>
                    <a href="https://www.lafabricaplay.com/wellness">Wellness</a>
                </li>
            </NavbarResponsive>
        </ToggleNavbarWrapper>
    );
}

export default ToggleNavbar;