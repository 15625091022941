import { useState, useEffect } from 'react';
import Navbar from '../components/Navbar';
import Modal from '../components/Modal';
import RegisterForm from '../components/RegisterForm';
import Header from '../components/Header';
import Features from '../components/Features';
import Process from '../components/Process';
import Footer from '../components/Footer';
import Program from '../components/Program';
import { ButtonInscribete, ButtonWrapper } from '../components/Header/header-styling';
import { motion } from 'framer-motion';

const Home = () => {
    const [ show, setShow ] = useState(false);

    const handleShow = () => {
      setShow(!show);
    }
    const goLaFabricaPlay = () => {
      window.location.href = "https://www.lafabricaplay.com/recovery";
    }

    useEffect(() => {
      localStorage.removeItem('data');

      const formScript = document.createElement('script');
      formScript.text = `        
      hbspt.forms.create({
        region: "na1",
        portalId: "8539297",
        formId: "43866368-a0a2-4743-9f77-6d16ef339ff9"
      });`;
      
      document.querySelector(".form-hbspt").appendChild(formScript);
      
      return () => {
        document.querySelector(".form-hbspt").removeChild(formScript);
      }

    }, []);


    return (
      <motion.div
      initial="pageInitial"
      animate="pageAnimate" variants={{
        pageInitial: {
          opacity: 0
        },
        pageAnimate: {
          opacity: 1
        },
        }}
    >
          <Navbar />
          <Header  />
          <Program />
          <Features />
          <ButtonWrapper>
            <div className="form-hbspt">
            </div>
          </ButtonWrapper>
          <Footer />
        </motion.div>
      );
}

export default Home;
