import Feature from '../Feature';
import { Container } from '../Header/header-styling';
import { Features as FeaturesWrapper } from './features-styling';

const Features = () => {
    return(
        <Container>
            <FeaturesWrapper>
                <Feature quantity="30" description="Clases" />
                <Feature quantity="8" description="Coaches" />
                <Feature quantity="600" description="Minutos" />
                <Feature quantity="4" description="Disciplinas" />
            </FeaturesWrapper>
        </Container>
    );
}

export default Features;