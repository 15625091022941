import styled from 'styled-components';

export const ToggleNavbar = styled.div`
    display: none;
    @media (max-width: 768px) {
        display: flex;
    }
`;

export const ToggleButton = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    cursor: pointer;

    & div {
        position: relative;
        width:  calc(100%/2);
        background: black;
        border-radius: 2px;
        ${props => props.open ? "height: 0px;" : "height: 3px;"}

        &::after, &::before {
            position: absolute;
            content: " ";
            width: 100%;
            transition: all 0.3s ease-out;
            ${props => props.open ? "top:calc(100% / 2);" : ""}

        }

        &::after {
            height: 3px;
            background-color: black;
            border-radius: 2px;
            ${props => props.open ? "transform: rotate(45deg);" : "top: 10px;"}

        }

        &::before {
            height: 3px;
            bottom: 10px;
            background-color: black;
            border-radius: 2px;
            ${props => props.open ? "transform: rotate(-45deg);" : "bottom: 10px;"}

        }
    }
`;

export const NavbarResponsive = styled.div`
    position: absolute;
    width: 100%;
    padding: 0px;
    top: 10vh;
    right: 0;
    text-align: center;
    border-radius: 3px;
    background: rgba(0, 0, 0, 0.9);
    border-top: 1px solid white;
    list-style: none; 
    visibility: ${props => props.open ? "visible" : "hidden"};
    opacity: ${props => props.open ? "1" : "0"};

    transition: all .2s ease-out;


    

    & li{
        padding: 20px;
        font-size: 1.5rem;
        color: white;
        
        & a {
            transition: all .2s ease-out;
            color: white;
        }
    }

}

`;