import { Navbar as Nav, Logo, Links, Icons } from './navbar-styling'
import  ToggleNavbar  from '../ToggleNavbar';

const Navbar = () => {
  return (
    <Nav>
      <a href="https://www.lafabricaplay.com/">
        <Logo src="https://lafabricaplay.com/checkout/assets/img/la-fabrica-play-logo.png" />
      </a>
      <Links>
        <li>
          <a href="https://www.lafabricaplay.com/browse">All Clases</a>
        </li>
        <li>
          <a href="https://www.lafabricaplay.com/live">Calendario Live</a>
        </li>
        <li>
          <a href="https://www.lafabricaplay.com/disciplinas">Disciplinas</a>
        </li>
        <li>
          <a href="https://www.lafabricaplay.com/wellness">Wellness</a>
        </li>
      </Links>
      <ToggleNavbar />
      <Icons>
      </Icons>
    </Nav>
  );
}

export default Navbar;