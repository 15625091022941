import styled from 'styled-components';
import { ButtonInscribete } from '../Header/header-styling';

export const Success = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 80vh;
    & p {
        font-size: 2.1rem;
        text-align: center;
     }
`;
export const Message = styled.h3`
    font-size: 3rem;
    text-align: center;
    margin-top: 90px;
    margin-bottom: 25px;
`;

export const Button = styled(ButtonInscribete)`
    text-align: center;
`;

export const AppInfo = styled.div`
    & p {
        text-align: center;
        font-size: 2.1rem;
    }
    & div {
        margin-bottom: 25px;
    }
    & img {
        width: 120px;
    }
`;