import Navbar from '../components/Navbar';
import Success from '../components/Success'; 
import Footer from '../components/Footer';

const Exito = () => {
    return(
       <>
            <Navbar />
            <Success />        
            <Footer />
       </>
    );
}

export default Exito;