import { useEffect, useState } from 'react';
import { Message, Button, Success as SuccessWrapper, AppInfo } from './success-styling';
import { useLocation, useHistory } from 'react-router-dom';
import Loading from '../Loading';



const Success = () => {
    const [ loading, setLoading ] = useState(false);
    const search = useLocation().search;
    const history = useHistory();

    useEffect(() => {
        const name = new URLSearchParams(search).get("session_id");
        if(name) {
            const dataStore = localStorage.getItem('data');
            fetch('https://lafabricaplay.com/checkout/api/check_checkout_session.php', {
                method: "POST",
                body: dataStore
            }).then(result => result.json())
            .then(data => {
                setLoading(false);
                localStorage.removeItem('data');
            }).catch(e => {
                console.log(e);
            })
        }else{ 
            history.push("/")
        };
    }, []);


    return(
        loading ? <Loading /> :
        <SuccessWrapper>
            <Message>¡Ya eres parte del programa Recovery!</Message>
            <p>Empieza a entrenar apartir del 7 de abril.</p>
            <AppInfo>
                <p>Descarga la App</p>
                <div>
                    <a href="https://play.google.com/store/apps/details?id=com.lafabricaplay&hl=es&gl=US">
                        <img src="/assets/img/boton_app_play_strore_2.png" alt=""/>
                    </a>
                    <a href="https://apps.apple.com/mx/app/la-fabrica-play/id1539931124">
                        <img src="/assets/img/boton_app_store_español.png" alt=""/>
                    </a>
                </div>
            </AppInfo>
            <Button href="https://www.lafabricaplay.com/login">Empieza a entrenar</Button>

        </SuccessWrapper>
    );
}

export default Success;