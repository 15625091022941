import styled from 'styled-components';

export const Navbar = styled.nav`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1rem;
`;

export const Logo = styled.img`
    width: 150px;
    height: 56px;
`;

export const Links = styled.ul`
    display: flex;
    margin-right: 120px;
    & li{
        margin-right: 15px;
    }
    & li a {
        font-size: 1.7rem;
        color: black;
    }
    & li a:hover {
        color: rgba(0, 0, 0, 0.7);
    }
    
@media (max-width: 768px) {
        display: none;
}
`;
export const Icons = styled.div`
    & svg {
        margin-right: 15px;
        cursor: pointer;
    }
    & svg:hover {
        color: rgba(0, 0, 0, 0.7);

    }
    & svg:last-child {
        margin-right: 0px;
    }
    @media (max-width: 768px) {
        display: none;
}
`;