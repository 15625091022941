import styled from 'styled-components';

export const Feature = styled.div`

`;

export const Quantity = styled.p`
    font-size: 5rem;
    font-weight: 600;
    margin-bottom: 0px;

    & span {
        color: rgba(48, 150, 5, .8);
        margin-left: -4px;
    }
`;

export const Description = styled.p`
    font-size: 2.2rem;
    margin-top: -10px;

`;