import { BrowserRouter as Router, Route } from 'react-router-dom';
import Home from './pages/home';
import Exito from './pages/exito';

function App() {
  return (
    <Router>
          <Route exact path="/" component={Home} />
          <Route exact path="/exito" component={Exito} />
    </Router>
  );
}

export default App;
